// src/components/DeleteFileComponent.js
import React, { useState } from 'react';
import axios from 'axios';

const DeleteFileComponent = () => {
    const [fileName, setFileName] = useState('');

    const handleDelete = async () => {
        if (!fileName) {
            alert('Please enter a file name.');
            return;
        }

        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/delete-file`, { params: { file_name: fileName } });
            alert(response.data.message);
            setFileName(''); // Reset input after deletion
        } catch (error) {
            console.error('Error deleting file:', error);
            alert(error.response.data.detail || 'Error deleting file');
        }
    };

    return (
        <div>
            <input
                type="text"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                placeholder="Enter file name"
            />
            <button onClick={handleDelete}>Delete File</button>
        </div>
    );
};

export default DeleteFileComponent;
