import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ListFilesComponent = () => {
    const [files, setFiles] = useState([]);

    // Encapsulate the fetching logic into a separate function
    const fetchFiles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/list-files`);
            setFiles(response.data.files);
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    // Use useEffect to call fetchFiles on component mount
    useEffect(() => {
        fetchFiles();
    }, []);

    return (
        <div>
            <ul>
                {files.map((file, index) => (
                    <li key={index}>{file}</li>
                ))}
            </ul>
            {/* Add a button that calls fetchFiles when clicked */}
            <button onClick={fetchFiles}>Refresh List</button>
        </div>
    );
};

export default ListFilesComponent;
