// src/App.js
import React, { useState } from 'react';
import './App.css';
import LoginComponent from './components/LoginComponent';
import FileUploadComponent from './components/FileUploadComponent';
import ListFilesComponent from './components/ListFilesComponent';
import DeleteFileComponent from './components/DeleteFileComponent';
import RunScriptComponent from './components/RunScriptComponent';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLoginSuccess = (token) => {
    localStorage.setItem('token', token); // Store the token
    setIsLoggedIn(true);
  };

  if (!isLoggedIn) {
    return <LoginComponent onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <div className="App">
      <h1>Ingression Manager</h1>
      <h2>All Current Files in S3</h2>
      <ListFilesComponent />
      <h2>Upload Your Files to S3</h2>
      <FileUploadComponent />
      <h2>Delete Files from S3</h2>
      <DeleteFileComponent />
      <h2>Update Ingression in Zep and Redis</h2>
      <RunScriptComponent />
      {/* Logout Button or Link */}
      <button onClick={() => {localStorage.removeItem('token'); setIsLoggedIn(false);}}>Logout</button>
    </div>
  );
}

export default App;