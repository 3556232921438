// src/components/RunScriptComponent.js
import React, { useState } from 'react';
import axios from 'axios';

const RunScriptComponent = () => {
    const [isRunning, setIsRunning] = useState(false);
    const [scriptOutput, setScriptOutput] = useState('');

    const handleRunScript = async () => {
        setIsRunning(true); // Start the script execution
        setScriptOutput(''); // Reset output
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/run-script`);
            setIsRunning(false); // Script execution completed
            setScriptOutput(response.data.output || 'No output from script.'); // Set output from script
            alert(response.data.message); // Alert the success message
        } catch (error) {
            console.error('Error running script:', error);
            setIsRunning(false); // Ensure to stop the loading state in case of error
            alert(error.response.data.detail || 'Error running script');
        }
    };

    return (
        <div>
            <button onClick={handleRunScript} disabled={isRunning}>
                {isRunning ? 'Please wait, processing...' : 'Run Ingression'}
            </button>
            {scriptOutput && (
                <div style={{ marginTop: '10px', maxHeight: '300px', overflowY: 'scroll', border: '1px solid black', padding: '10px' }}>
                    <pre>{scriptOutput}</pre>
                </div>
            )}
        </div>
    );
};

export default RunScriptComponent;
